<template>
  <div>
    <el-dialog title="走访记录详情" class="cus_dialog" :visible.sync="dialogshow" :close-on-click-modal="false"
      :modal-append-to-body="false" :destroy-on-close="true" @close="closeDialog" width="800px">
      <div slot="title" class="flexBetween">
        <span style="color: #ffffff"><span style="font-weight: 700">{{ EditItem.username }}</span> 走访记录详情</span>
      </div>
      <div>
        <div v-if="EditItem" class="cellGroupBarTitle flexStart" style="margin-top: 10px">
          <span></span>
          <span style="font-weight: 400">走访详情</span>
        </div>
        <table class="dttable" v-if="EditItem">
          <tr>
            <td class="tdheader">走访教师</td>
            <td style="width: 30%">{{ EditItem.username }}</td>
            <td class="tdheader">走访日期</td>
            <td style="width: 30%">{{ EditItem.visit_date }}</td>
          </tr>

          <tr>
            <td class="tdheader">走访学生</td>
            <td colspan="3">
              <table class="dttable">
                <tr v-for="(stu, i) in EditItem.stu_list1" :key="i">
                  <td>{{ stu.stu_name }}</td>
                  <td>{{ stu.stu_code }}</td>
                  <td>{{ stu.class_name }}</td>
                  <td>{{ stu.firm_name }}</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td class="tdheader">企业名称</td>
            <td>
              {{ EditItem.enter_name }}
            </td>
            <td class="tdheader">岗位</td>
            <td>
              {{ EditItem.job }}
            </td>
          </tr>
          <tr>
            <td class="tdheader">走访地址</td>
            <td colspan="3" style="word-break: break-all">
              {{ EditItem.visit_address }}
            </td>
          </tr>
          <tr>
            <td class="tdheader">接待人员及职位</td>
            <td colspan="3" style="word-break: break-all">
              {{ EditItem.jdrjzw }}
            </td>
          </tr>
          <tr>
            <td class="tdheader">走访小结</td>
            <td colspan="3" style="word-break: break-all">
              {{ EditItem.visit_summary }}
            </td>
          </tr>
          <tr>
            <td class="tdheader">走访照片</td>
            <td colspan="3">
              <div v-if="!EditItem.fieldname || EditItem.fieldname.length==0">-</div>
              <div style="display: inline-block; margin-right: 10px; vertical-align: top; position: relative"
                v-for="(img, idx) in EditItem.fieldname" :key="idx">
                <el-image style="width: 100px; height: 100px" :src="img.fileUrl || img.src"
                  :preview-src-list="[img.fileUrl || img.src]" fit="cover" :z-index="3000"></el-image>
              </div>

              
            </td>
          </tr>

          <tr>
            <td class="tdheader">走访表拍照</td>
            <td colspan="3">
              <div v-if="!isEdit && (!EditItem.fieldname1 || !EditItem.zf_url)">-</div>
              <div style="display: inline-block; margin-right: 10px; vertical-align: top; position: relative"
                v-for="(img, idx) in EditItem.fieldname1" :key="idx">
                <el-image style="width: 100px; height: 100px" :src="img.fileUrl || img.src"
                  :preview-src-list="[img.fileUrl || img.src]" fit="cover" :z-index="3000"></el-image>

                <div class="imgremove" v-if="isEdit" @click="removeImg(idx)"><i class="el-icon-remove"></i></div>
              </div>

              <div style="display: inline-block; vertical-align: top"
                v-if="isEdit && (!EditItem.fieldname1 || EditItem.fieldname1.length == 0 || (EditItem.fieldname1 && EditItem.fieldname1.length < 1)) ">
                <el-upload style="display: inline-block; margin-right: 20px" action="" :http-request="uploadOss"
                  :on-success="uploadFiles" :show-file-list="false" name="image">
                  <div class="imgaddbtn">＋</div>
                </el-upload>
              </div>
              <div v-if="isEdit" style=" margin-top:10px;color:#3378fa;">提示：<el-button icon="el-icon-download"
                  size="small" type="primary" plain style="margin: 0 10px 10px 0;"
                  @click="downLoad">点此下载走访表PDF</el-button>下载后打印， 并盖章拍照上传</div>
            </td>
          </tr>
        </table>



        <!-- <el-row v-if="!EditItem.is_draft&&EditItem.audit_status !=1">
					<el-col :span="16" style="text-align: right">
						<el-input v-model.trim="spword" style="width: 100%" size="small" placeholder="审核拒绝时请填写拒绝原因">
						</el-input>
					</el-col>
					<el-col :span="8" style="text-align: left;padding-left: 10px;">
						<el-button size="small" type="warning" @click="submitForm(2)">审批拒绝</el-button>
						<el-button size="small" type="primary" @click="submitForm(1)">审批通过</el-button>
					</el-col>
				</el-row> -->

        <el-row v-if="isEdit" style="margin-top:20px;">
          <el-col :span="22" style="text-align: right; padding-left: 10px">
            <el-button size="small" icon="el-icon-close" type="" @click="closeDialog">关闭</el-button>

            <el-button size="small" icon="el-icon-check" type="primary" @click="submitForm(0)"> 上传走访表拍照图</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["id", "xunfangsp",'isEdit'],
  data() {
    return {
      dialogshow: true,
      userinfo: null,
      EditItem: { fieldname1 :[]},
      process_code: "leave_1",
      needChoose: false, //是否需要选择下级
      choosedUser: [], //已选择的用户.
      spword: "",
      listForm: [],
      listState: [],
      objForm: {},
      objState: {},
    };
  },
  mounted() {
    this.getTeacheInfo();
    this.getAllPickerVal();
  },
  methods: {
    getTeacheInfo() {
      this.$http.post("/api/get_teacher_info").then((res) => {
        this.userinfo = res.data;
      });
    },
    getData() {
      this.$http.post("/api/teacher_visit_info", { id: this.id }).then((res) => {
        try {
          
          if (res.data.stu_list) {
            res.data.stu_list1 = JSON.parse(res.data.stu_list)
          } else {
            res.data.stu_list1 = [];
          }
          let stu_list = JSON.parse(JSON.stringify(res.data.stu_list1))
          res.data.stu_list_lable = stu_list.map(e => { return e.stu_name }).join("，")
          if (res.data.zf_url) {
            res.data.fieldname1 = [
              {
                fileName: 1,
                fileUrl: res.data.zf_url,
              },
            ];
          } else {
            res.data.fieldname1 = []
          }
          res.data.fieldname = JSON.parse(res.data.fieldname)

          res.data.visit_form += "";
          res.data.situation = parseInt(res.data.situation);
          res.data.visit_form_label = this.objForm[res.data.visit_form];
          res.data.situation_label = this.objState[res.data.situation];
          res.data.situation_label = this.listState.filter((item) => res.data.situation == item.param_value).map((m) => m.param_desc)[0];
          res.data.visit_date = res.data.visit_date.substring(0, 10);

          this.EditItem = res.data;
          console.log(158, this.EditItem)
        } catch (error) {}
        
      });
    },
    getAllPickerVal() {
      //走访形式&&走访情况
      this.$http.post("/api/sys_param_list", { param_type: "'VISIT_FORM','VISIT_SITUATION'" }).then((res) => {
        let listForm = [],
          listState = [],
          objForm = {},
          objState = {};
        res.data.map((item, index, arr) => {
          if (item.param_type == "VISIT_FORM") {
            listForm.push(item);
            objForm[item.param_value] = item.param_desc;
          }
          if (item.param_type == "VISIT_SITUATION") {
            listState.push(item);
            objState[item.param_value] = item.param_desc;
          }
        });

        this.listForm = listForm;
        this.listState = listState;
        this.objForm = objForm;
        this.objState = objState;
        this.getData();
      });
    },
    closeDialog() {
      this.$$parent(this, "dialogshow", false);
    },
    uploadFiles(e) {
      console.log(189, e)
      if (!this.EditItem.fieldname1) {
        this.EditItem.fieldname1 = []
      }
      this.EditItem.fieldname1.push(e);
    },
    removeImg(idx) {
      this.EditItem.fieldname1.splice(idx, 1);
    },

    preCheckData(e) {
      let DataInfo = this.EditItem;

      if (DataInfo.visit_summary.length < 100) {
        this.$message({
          type: "error",
          message: "走访小结必须大于100字",
        });
        return false;
      }
    },
   

    isChooseuser(e) {
      //设置是否需要选人
      this.needChoose = e;
    },
    chooseUser(e) {
      //设置选择的人

      this.choosedUser = e;
    },

    //导出
    downLoad() {

      let params = {
        "stuArr": [{
          "id": this.EditItem.teacher_id,
          "dataid": this.EditItem.id,
          "name": this.EditItem.username,
          "visit_date": this.EditItem.visit_date.substring(0, 10),
        }],
        "pmid": window.pmid,
        "latest": 1,
        "pageFrom": "xflist",
      }

      this.$http.post(this.$pdfUrl + "/exportTchPdf_zf", params).then(res => {
        this.$message({
          type: 'success',
          message: '生成成功!'
        });
        window.open(res.data.url, "_blank")
      })
    },

    submitForm(st) {
        let data = JSON.parse(JSON.stringify(this.EditItem));
      data.fieldname1 = JSON.stringify(data.fieldname1);

      if (!this.EditItem.fieldname1 || this.EditItem.fieldname1.length == 0) {
        this.$message.error("请上传走访表拍照（盖章）~");
        return;
      }
      data.zf_url = this.EditItem.fieldname1[0].src || this.EditItem.fieldname1[0].fileUrl
      if (!data.visit_form||data.visit_form == 'null') {
        data.visit_form = 0

      }
      this.$http.post("/api/teacher_zoufang_edit", data).then((res) => {
        this.$message({
          type: "success",
          message: '上传成功',
        });
        this.$$parent(this, "getList");
        this.$$parent(this, "dialogshow", false);
      });

    },
    closeDialog() {
      this.$$parent(this, "dialogshow", false);

    }
  },
};
</script>

<style scoped>
.imgaddbtn {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dotted #ccc;
  font-size: 30px;
  cursor: pointer;
}

.imgremove {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;

  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
  color: #ccc;
}

.imgremove:hover {
  color: orange;
}

.cellGroupBarTitle {
  margin-bottom: 10px;
}
.cellGroupBarTitle span:nth-of-type(2) {
  color: #2469f3;
}
</style>
