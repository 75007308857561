<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap; width: 100%">
      <div class="flexStart flex_1" style="flex-wrap: wrap">
        <el-date-picker size="small" v-model="searchForm.month" value-format="yyyy-MM" type="month" placeholder="选择月"
          style="width: 150px; margin: 0 10px 10px 0"> </el-date-picker>
        <el-input placeholder="请输入关键字" v-model="searchForm.keyword" size="small" class="input-with-select"
          style="width: 300px; margin: 0 10px 10px 0" clearable>
          <el-select v-model="searchForm.ktype" slot="prepend" placeholder="请选择" style="width: 120px">
            <el-option label="教师名称" value="username"></el-option>
            <!-- <el-option label="企业名称" value="enter_name"></el-option> -->
          </el-select>
        </el-input>
        <!-- 
        <el-select v-if="xunfang_sp" v-model="searchForm.audit_status" clearable placeholder="请选择" size="small"
          style="width: 130px; margin: 0 10px 10px 0">
          <el-option label="全部状态" value=""></el-option>
          <el-option label="草稿" :value="-1"></el-option>
          <el-option label="待审核" :value="0"></el-option>
          <el-option label="审核通过" :value="1"></el-option>
          <el-option label="审核拒绝" :value="2"></el-option>
        </el-select>
        <el-tooltip v-if="xunfang_sp" class="item" effect="dark" content="提示：草稿状态只能查看当前条件下自己的草稿" placement="top">
          <i class="el-icon-question" style="font-size: 20px; margin: 0 10px 10px 0"></i>
        </el-tooltip> -->

        <!-- <el-cascader ref="firstCascader" :options="ClassTree" :props="defaultProps"
					v-model="searchForm.office_id" :show-all-levels="false" clearable placeholder="请选择组织" filterable
					style="width: 150px;margin-left:20px" size="small">
				</el-cascader> -->

        <tchtree v-model="searchForm.office_id" style="margin: 0 10px 10px 0"></tchtree>

        <!--				<el-select v-model="searchForm.class_id" clearable placeholder="请选择" size="small"-->
        <!--					style="width:130px;margin-left:20px">-->
        <!--					<el-option label="全部" value=""></el-option>-->
        <!--					<el-option :label="cls.name" v-for="(cls, idx) in ClassList" :key="idx" :value="cls.id"></el-option>-->

        <!--				</el-select>-->

        <el-button icon="el-icon-search" style="margin: 0 10px 10px 0" size="small" type="primary" @click="onSearch"> 搜索
        </el-button>
        <el-button icon="el-icon-plus" size="small" type="primary" plain style="margin: 0 10px 10px 0;"
          @click="addNew">新增走访</el-button>
      </div>

      <!-- <div class="flexStart" style="color: #3378fa; font-size: 14px; font-weight: 700; margin: 0 5px 0 30px; cursor: pointer">
        <el-button type="primary" plain icon="el-icon-download" style="margin: 0 10px 10px 0;" size="small"
					@click="exportPdfs_zf">
					导出走访手册
				</el-button>
      </div> -->
    </el-row>
    <div class="dividerBar"></div>

    <el-table v-tableFit ref="multipleTable" :data="DataList" height="100%" size="small" stripe border
      style="width: fit-content; width: 100%" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" width="65" label="序号" align="center" />

      <el-table-column prop="username" label="走访教师" width="135" align="center" />
      <el-table-column prop="visit_date" label="走访时间" width="240" align="center"> </el-table-column>

      <el-table-column prop="enter_name" label="企业名称" show-overflow-tooltip width="200" align="center" />
      <el-table-column prop="username" label="学生姓名" show-overflow-tooltip width="150" align="center">
        <template slot-scope="scope">
          <span class="stuname" v-for="(s, i) in scope.row.stu_list" :key="s.id">
            {{ s.stu_name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="visit_address" label="走访地址" min-width="120"></el-table-column>
      <el-table-column prop="jdrjzw" label="接待人员及职位" min-width="120"></el-table-column>

      <el-table-column fixed="right" label="操作" width="220" align="center">
        <template slot-scope="scope">
          <el-tag class="pointer" type="text" size="mini" @click="viewInfo(scope.row)">详情</el-tag>
          <el-tag class="pointer ml10" type="success" size="mini" @click="modify(scope.row)"
            v-if="$store.state.teacher.id == scope.row.create_uid"> 上传走访表拍照 </el-tag>

          <el-popconfirm v-if="isAdmin || ( $store.state.teacher.id == scope.row.create_uid)" width="250"
            confirmButtonText="确定" cancelButtonText="取消" icon="el-icon-info" iconColor="red"
            @confirm="handleDelete(scope.row)" placement="left" title="是否确定删除？">
            <el-tag class="pointer" slot="reference" type="danger" size="mini" style="margin-left: 10px">删除</el-tag>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination v-if="showPage" @current-change="handlePageChange" @size-change="handleSizeChange"
      :current-page="page.current_page" :page-size="page.per_page" :total="page.count"
      layout="total, sizes,prev, pager, next" :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px"> </el-pagination>
    <el-pagination v-if="!showPage" :current-page="page.current_page" :total="page.count" layout="total"
      style="margin-top: 10px"> </el-pagination>

    <formdialog v-if="dialogshow" :id="EditItem.id" :xunfangsp="xunfang_sp" :isEdit="isEdit"></formdialog>

    <el-dialog ref="ruleForm" title="新增走访" :visible.sync="showFormDialog" :close-on-click-modal="false"
      :modal-append-to-body="false" :before-close="resetForm" :destroy-on-close="true" custom-class="cus_dialog">
      <el-form :model="EditItem" label-width="130px" ref="formData">
        <el-form-item v-if="EditItem.audit_status == 2" label="驳回原因" prop="visit_date">
          <div style="color: #f56c6c">{{ EditItem.audit_remark }}</div>
        </el-form-item>
        <el-form-item class="is-required" label="走访日期" prop="visit_date">
          <el-date-picker v-model="EditItem.visit_date" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间" :clearable="false"> </el-date-picker>
        </el-form-item>
        <el-form-item class="is-required" label="走访学生">
          <el-tag v-for="item in multipleSelectionStu" :key="item.id" type="success" style="margin-right: 5px">{{
            item.stu_name }}</el-tag>
          <el-button type="primary" icon="el-icon-plus" size="small" plain @click="setStuSelection"> 选择学生</el-button>
        </el-form-item>
        <template v-if="multipleSelectionStu.length > 0">
          <el-form-item class="is-required" label="企业" prop="enter_name">
            <el-input v-model="EditItem.enter_name" @input="$forceUpdate()"></el-input>
          </el-form-item>
          <el-form-item class="is-required" label="岗位" prop="job">
            <el-input v-model="EditItem.job" @input="$forceUpdate()"></el-input>
          </el-form-item>
        </template>
        <el-form-item class="is-required" label="教师走访地址" prop="visit_address">
          <!-- <el-input v-model="EditItem.visit_address"></el-input> -->
          <el-autocomplete class="felx_1" v-model="EditItem.visit_address" @input="$forceUpdate()"
            :fetch-suggestions="onChangeLocation" @change="onChangeLocation" @select="onSelect" placeholder="请输入您的走访地址"
            style="width: 100%"> </el-autocomplete>

          <el-amap v-show="mapShow" vid="amapDemo" :zoom="zoom" :center="center" class="amap-demo" :events="events">
            <el-amap-text v-for="(text, index) in texts" :key="'text-' + index" :text="text.text" :offset="text.offset"
              :position="text.position"></el-amap-text>
            <el-amap-marker v-for="(marker, index) in markers" :key="'marker-' + index" :position="marker.position"
              :events="marker.events" :visible="marker.visible" :draggable="marker.draggable"
              :vid="index"></el-amap-marker>
          </el-amap>
          <el-divider type="horizontal" />
        </el-form-item>
        <el-form-item class="is-required" label="接待人员及职务" prop="situation">
          <el-input @input="$forceUpdate()" v-model="EditItem.jdrjzw"></el-input>
        </el-form-item>
        <el-form-item class="is-required" label="走访小结" prop="visit_summary">
          <el-input @input="$forceUpdate()" type="textarea" v-model="EditItem.visit_summary"
            :autosize="{ minRows: 2 }"></el-input>
        </el-form-item>
        <el-form-item class="is-required" label="上传走访照片">
          <div style="display: inline-block; margin-right: 10px; vertical-align: top; position: relative"
            v-for="(img, idx) in EditItem.fieldname" :key="idx">
            <el-image style="width: 100px; height: 100px" :src="img.fileUrl || img.src"
              :preview-src-list="[img.fileUrl || img.src]" img="cover"></el-image>

            <div class="imgremove" @click="removeImg(idx)"><i class="el-icon-remove"></i></div>
          </div>

          <div style="display: inline-block; vertical-align: top" v-if="(!EditItem.fieldname || EditItem.fieldname.length == 0 || (EditItem.fieldname && EditItem.fieldname.length < 1))">
            <el-upload style="display: inline-block; margin-right: 20px" action="" :http-request="uploadOss"
              :on-success="uploadFiles" :show-file-list="false" name="image" accept="image/png, image/jpeg">
              <div class="imgaddbtn">＋</div>
            </el-upload>
          </div>
        </el-form-item>
      </el-form>
      <div class="" slot="footer">
        <!-- <el-button v-if="EditItem.audit_status != 2" @click="onSubmit(1)" type="primary" plain>存草稿</el-button> -->
        <el-button @click="onSubmit(0)" type="primary">提交</el-button>
      </div>
    </el-dialog>
    <el-dialog width="600px" title="选择学生" :visible.sync="showStuList" :close-on-click-modal="false"
      :modal-append-to-body="false" @close="showStuList = false" :destroy-on-close="true" custom-class="cus_dialog">
      <el-table ref="multipleStuTable" :data="stuList" @selection-change="handleSelectionStuChange" height="100%"
        size="small" stripe border>
        <el-table-column type="selection" width="50"> </el-table-column>
        <el-table-column prop="stu_code" label="学号" width="100"> </el-table-column>
        <el-table-column prop="stu_name" label="姓名" width="100"> </el-table-column>
        <el-table-column prop="job" label="实习岗位" width="100"></el-table-column>
        <el-table-column prop="firm_name" label="实习企业" show-overflow-tooltip> </el-table-column>
      </el-table>
      <div class="" slot="footer">
        <el-button @click="confirmStuSelect" type="primary" :disabled="multipleSelectionStu.length === 0">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import formdialog from "./formdialog.vue";
import tchtree from "../com/tchTree.vue";
import { mapState } from "vuex";
import moment from "moment";
import { AMapManager } from "vue-amap";
let amapManager = new AMapManager();
export default {
  components: {
    formdialog,
    tchtree,
  },
  data() {
    return {
      temphead: "//school.mmteck.cn/static/temp.png",

      dialogshow: false,
      showFormDialog: false,
      showStuList: false,
      searchForm: {
        audit_status: "",
        ktype: "username",
        keyword: "",
        class_id: "",
        month: "",
      },
      EditItem: {
        id: 0,
        visit_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        fieldname: [],
        stu_list: [],
      },
      DataList: [],
      listForm: [],
      listState: [],
      ClassList: [],
      ClassTree: [],
      defaultProps: {
        label: "name",
        value: "id",
        children: "children",
        emitPath: false,
        checkStrictly: true,
      },
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0,
      },
      multipleSelection: [],

      xunfang_sp: 0, //是否开启走访审核
      showPage: true,
      pmid: 0,
      rules: {
        visit_date: [{ required: true, message: "请选择走访时间", trigger: "change" }],
        visit_form: [{ required: true, message: "请选择走访形式", trigger: "change" }],
        situation: [{ required: true, message: "请选择走访情况", trigger: "change" }],
        visit_summary: [{ required: true, message: "请输入走访小结", trigger: "blur" }],
        enter_name: [{ required: true, message: "请输入企业", trigger: "blur" }],
      },
      stuList: [],
      multipleSelectionStu: [],
      // 选点
      mapShow: false,

      zoom: 16,
      center: [116.397477, 39.908692],
      events: {
        click(e) {
          let { lng, lat } = e.lnglat;
          self.clickLng = lng;
          self.clickLat = lat;

          // 这里通过高德 SDK 完成。
          var geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: "all",
          });
          geocoder.getAddress([self.clickLng, self.clickLat], function (status, result) {
            if (status === "complete" && result.info === "OK") {
              if (result && result.regeocode) {
                self.$nextTick(() => {
                  self.center = [self.clickLng, self.clickLat];
                  self.markers[0].position = [self.clickLng, self.clickLat];
                  self.texts[0].position = [self.clickLng, self.clickLat];
                  self.texts[0].text = result.regeocode.formattedAddress;
                  self.address = result.regeocode.formattedAddress;
                });
              }
            }
          });
        },
        lng: 0,
        lat: 0,
        // },
      },
      markers: [
        {
          position: [116.397477, 39.908692],
          events: {
            // click: () => {
            //   alert('click marker');
            // },
            // dragend: e => {
            //   console.log('---event---: dragend');
            //   this.markers[0].position = [e.lnglat.lng, e.lnglat.lat];
            // }
          },
          visible: true,
          draggable: false,
          template: "<span></span>",
        },
      ],
      texts: [
        {
          position: [116.397477, 39.908692],
          text: "天安门",
          offset: [0, -70],
          // events: {
          //   click: () => {
          //     alert('click text');
          //   }
          // }
        },
      ],
      posBoxWidth: 0, //监听盒子宽度
      posBoxId: "",
      locationSource: [],
      latLng: "",
      timer: null,
      isEdit:false,
    };
  },
  watch: {
    showFormDialog(n, o) {
      console.log(n, o);
      if (!n) {
        this.mapShow = false;
      }
    },
  },
  filters: {
    handleDate(value) {
      // value是输入框的内容，也是要显示的内容
      if (value) {
        return value.substring(0, 10);
      }
    },
  },

  mounted() {
    this.getConfig();
    this.getOrgTree1();
  },
  computed: {
    ...mapState(["teacher"]),
    isAdmin() {
      if (this.teacher.sys_roles) {
        let roles = this.teacher.sys_roles;
        let arr = roles.filter((r) => r.role_code.indexOf("admin") >= 0).map((r) => r.role_code);
        return arr.length > 0;
      }
    },
  },
  methods: {
    //是否开启走访审核
    getConfig() {
      this.$http.post("/api/sys_sch_config_list", { ktype: "ktype", keyword: "xunfang_sp" }).then((res) => {
        if (res.data.data && res.data.data[0]) {
          this.xunfang_sp = Number(res.data.data[0].kvalue);
        }
      });
    },
    getOrgTree1() {
      this.$http.post("/api/get_teacher_info").then((res) => {
        /*for (let item of res.data.class_names) {
					if (item.type == "班主任" && window.pmid != 216) {
						this.searchForm.office_id = item.class_id
						break
					}
				}*/

        this.formData = res.data;

        this.getAllPickerVal();
        //this.getOrgTree()
      });
    },
    getOrgTree() {
      this.$http.post("/api/teacher_class_tree").then((res) => {
        this.ClassTree = res.data.tree;
      });
    },
    getAllPickerVal() {
      //走访形式&&走访情况
      this.$http.post("/api/sys_param_list", { param_type: "'VISIT_FORM','VISIT_SITUATION'" }).then((res) => {
        let listForm = {},
          listState = {};
        res.data.map((item, index, arr) => {
          if (item.param_type == "VISIT_FORM") {
            listForm[item.param_value] = item.param_desc;
          }
          if (item.param_type == "VISIT_SITUATION") {
            listState[item.param_value] = item.param_desc;
          }
        });

        this.listForm = listForm;
        this.listState = listState;

        // 查询classid
        this.$http.post("/api/get_tch_cls_data").then((res) => {
          let classids = [];
          if (res.data.bj_ids) {
            if (res.data.bj_ids.indexOf(",") > 0) {
              classids = classids.concat(res.data.bj_ids.split(","));
            } else {
              classids.push(res.data.bj_ids);
            }
          }

          if (res.data.sxxz_ids) {
            if (res.data.sxxz_ids.indexOf(",") > 0) {
              classids = classids.concat(res.data.sxxz_ids.split(","));
            } else {
              classids.push(res.data.sxxz_ids);
            }
          }

          // 查询学生
          this.$http.post("/api/get_sxgroup_stus", { from:'zf',class_ids: classids.join(",") }).then((res) => {
            if (res.data && res.data.length > 0) {
              res.data.map((item, index) => {
                item.checked = false;
                item.remark = false;
              });
              this.stuList = res.data;
            }
          });
        });
        this.getList();
      });
    },
    getList() {
      return new Promise((resolve, reject) => {
        let data = {
          page: this.page.current_page,
          pagesize: this.page.per_page,
          month: this.searchForm.month,

          // class_id: this.searchForm.class_id,
          office_id: this.searchForm.office_id,
          audit_status: this.searchForm.audit_status == -1 ? "" : this.searchForm.audit_status,
          is_draft: this.searchForm.audit_status == -1 ? 1 : "",
          showPage: this.showPage,
        };
        if (this.searchForm.ktype == "enter_name") {
          data.enter_name = this.searchForm.keyword;
        } else if (this.searchForm.ktype == "username") {
          data.username = this.searchForm.keyword;
        }
        this.$http.post("/api/teacher_zoufang_list", data).then((res) => {
          for (let item of res.data.data) {
            item.stu_list = JSON.parse(item.stu_list);
            item.visit_date = item.visit_date.substring(0, 10);
          }
          this.DataList = res.data.data;
          this.page = res.data.page ? res.data.page : this.page;
          resolve();
        });
      });
    },
    onSearch() {
      let _this = this;
      this.page.current_page = 1;
      if (this.searchForm.ktype == "username" && this.searchForm.keyword) {
        this.showPage = false;
      } else {
        _this.showPage = true;
      }

      this.getList().then((_) => {
        if (_this.searchForm.ktype == "username" && _this.searchForm.keyword) {
          if (_this.DataList && _this.DataList.length > 0) {
            _this.page.count = _this.DataList.length;
            _this.multipleSelection = _this.DataList;
            _this.DataList.forEach((row) => {
              this.$refs.multipleTable.toggleRowSelection(row);
            });
          } else {
            _this.page.count = 0;
            _this.multipleSelection = [];
            this.$refs.multipleTable.clearSelection();
          }
        }
      });
    },
    handlePageChange(page) {
      this.page.current_page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList();
    },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
    addNew() {
      this.isEdit = false

      this.resetForm();
    },
    modify(row) {
      this.isEdit = true
      console.log(row)
      // this.multipleSelectionStu = row.stu_list;
      if (row.zf_url) {
        row.fieldname1 = [
          {
            fileName: 1,
            fileUrl: row.zf_url,
          },
        ];
      }
      this.EditItem = { ...row };
      this.EditItem.fieldname = JSON.parse(this.EditItem.fieldname)

      this.dialogshow = true;

      // this.showFormDialog = true;
    },

    viewInfo(row) {
      this.isEdit = false
      this.EditItem = row;
      this.dialogshow = true;
    },
    exportXls() {
      this.$http
        .post("/api/teacher_visit_list_export", {
          page: 1,
          pagesize: 2000,
          month: this.searchForm.month,
          enter_name: this.searchForm.keyword,
          // class_id: this.searchForm.class_id,
          office_id: this.searchForm.office_id,
          audit_status: this.searchForm.audit_status == -1 ? "" : this.searchForm.audit_status,
          is_draft: this.searchForm.audit_status == -1 ? 1 : "",
        })
        .then((res) => {
          if (res.data.url) {
            window.open(res.data.url);
          }
        });
    },
    exportPdfs_zf(row) {
      console.log(this.multipleSelection);
      let arr = [];

      if (!this.multipleSelection || this.multipleSelection.length == 0) {
        this.$message.warning("请先选择教师~");
      } else {
        this.multipleSelection.map((a) => {
          arr.push({
            id: a.teacher_id,
            name: a.username,
            dataid: a.id,
            visit_date: a.visit_date.substring(0, 10),
          });
        });
        let params = {
          stuArr: arr,
          pmid: window.pmid,
          latest: 1,
          pageFrom: "xflist",
          month: this.searchForm.month,
        };
        // this.$http.post("http://localhost:30022/exportTchPdf_img_zf", params).then(res => {
        // this.$http.post(this.$pdfUrl + "/exportTchPdf_img_zf", params).then((res) => {

        this.$http.post(this.$pdfUrl + "/exportTchPdf_zf", params).then((res) => {
          if (arr.length > 10) {
            let loadingInstance = this.$loading({
              lock: true,
              text: "正在导出请稍等...",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.5)",
            });
            this.timer = setInterval(async () => {
              let result = await this.$http.post(this.$pdfUrl + "/getFileUrl", { rkey: res.data.id, noloading: true });
              if (result.data.url) {
                clearInterval(this.timer);
                loadingInstance.close();
                window.open(result.data.url, "_blank");
              }
            }, 3000);
          } else {
            this.$message({
              type: "success",
              message: "生成成功!",
            });
            window.open(res.data.url, "_blank");
          }
        });
      }
    },
    tongbuJava() {
      this.$confirm("请确保JAVA版本已存在？是否继续同步?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let loadingInstance = this.$loading({
            lock: true,
            text: "已发送请求，请稍等...",
            spinner: "el-icon-loading",
          });
          this.$http
            .post("/api/sync_visit_record", {})
            .then((res) => {
              loadingInstance.close();
              this.$message.success("后台同步中，请刷新查看");
              this.getList();
            })
            .catch((err) => {
              this.$message.error("同步失败");
              loadingInstance.close();
            });
        })
        .catch(() => {});
    },
    handleDelete(row) {
      this.$http
        .post("/api/teacher_visit_delete", {
          id: String(row.id),
        })
        .then((res) => {
          this.$message.success("删除成功");
          this.getList();
        });
    },
    onSubmit(isDraft) {
      //   this.$refs.formData.validate((valid) => {
      //     if (valid) {
      if (!this.EditItem.visit_date) {
        this.$message.error("请选择走访日期~");
        return;
      }
      
      if (this.multipleSelectionStu.length <= 0) {
        this.$message.error("请选择学生");
        return;
      }
      if (this.$store.state.pmid == 214 && !this.EditItem.visit_address && this.EditItem.visit_address != 0) {
        this.$message.error("请填写走访地址~");
        return;
      }
    
      if (!this.EditItem.visit_summary && this.EditItem.visit_summary != 0) {
        this.$message.error("请填写走访小结~");
        return;
      }
      if (!this.EditItem.fieldname || this.EditItem.fieldname.length == 0) {
        this.$message.error("请上传走访照片~");
        return;
      }
      let params = { ...this.EditItem };
      params.stu_list = JSON.stringify(this.multipleSelectionStu);
      params.fieldname = JSON.stringify(this.EditItem.fieldname);
    
      params.stu_num = this.multipleSelectionStu.length;
      // params.is_draft = isDraft;
      // params.visit_type = 1;

      if (this.latLng && this.latLng.indexOf(",") > 0) {
        let arr = this.latLng.split(",");
        params.longitude = arr[0];
        params.latitude = arr[1];
      }
      this.$http.post("/api/teacher_zoufang_edit", params).then((res) => {
        this.$message.success("提交成功");
        this.getList();
        this.resetForm();
      });
      // } else {
      //   console.log('error submit!!');
      //   return false;
      // }
      //   });
    },
    uploadFiles(e) {
      this.EditItem.fieldname.push(e);
    },
    removeImg(idx) {
      this.EditItem.fieldname.splice(idx, 1);
    },
    handleSelectionStuChange(selection) {
      if (selection.length > 1) {
        //移除上一次选中行数据
        selection.shift();
        //修改选中图标为未选中状态
        this.$refs.multipleStuTable.clearSelection();
        //将当前选中行改为选中状态
        this.$refs.multipleStuTable.toggleRowSelection(selection[0]);
      }
      this.multipleSelectionStu = selection;

      // if (selection.length === 0) {
      //   this.multipleSelectionStu = null;
      // } else {
      //   // 只保留最后一个选中的行
      //   this.multipleSelectionStu = selection[selection.length - 1];
      // }
      // console.log(693, selection)

      // // 清除其他选中项
      // this.$refs.multipleStuTable.clearSelection();
      // console.log(695,this.multipleSelectionStu)
      // // 如果当前行不为null，则重新选中
      // if (this.multipleSelectionStu) {
      //   this.$refs.multipleStuTable.toggleRowSelection(this.multipleSelectionStu, true);
      // }

    },
    setStuSelection() {
      this.showStuList = true;
      this.$nextTick(() => {
        this.multipleSelectionStu.forEach((row) => {
          this.$refs.multipleStuTable.toggleRowSelection(
            // 此处必须在 tableData 中查找对应的数据，否则 toggleRowSelection 会失效
            this.stuList.find((r) => {
              return r.id === row.id;
            }),
            true
          );
        });
      });
    },
    confirmStuSelect() {
      this.showStuList = false;
      this.EditItem.enter_name = this.multipleSelectionStu[0].firm_name;
      this.EditItem.enter_id = this.multipleSelectionStu[0].stu_enter_id;
      this.EditItem.job = this.multipleSelectionStu[0].job;
    },
    resetForm() {
      this.isEdit = false
      this.showFormDialog = !this.showFormDialog;
      this.multipleSelectionStu = [];
      this.EditItem = {
        id: 0,
        visit_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        fieldname: [],
        stu_list: [],
      };
      this.$nextTick(() => {
        this.$refs.formData.resetFields();
      });
    },
    onChangeLocation(value, callback) {
      console.log(763, value);
      let locationSource = [];
      if (value) {
        this.EditItem.visit_address = value;
        $.get("https://restapi.amap.com/v3/assistant/inputtips?output=json&keywords=" + this.EditItem.visit_address + "&key=0b5d4c06598b47042b9b5257889f7e26", (res) => {
          if (res.tips) {
            locationSource = res.tips.map((o) => {
              return { value: o.name };
            });
          }
          this.locationSource = res.tips;
          callback(locationSource);
        });
      } else {
        callback(locationSource);
      }
    },
    onSelect(obj) {
      console.log(781, obj);
      this.EditItem.visit_address = obj.value;
      this.locationSource.forEach((element) => {
        console.log(776, element);
        if (element.name == this.EditItem.visit_address) {
          if (element.location && element.location.length > 0) {
            this.mapShow = true;
            const locArr = element.location.split(",").map(Number);
            this.center = locArr;
            this.markers[0].position = locArr;
            this.texts[0].position = locArr;
          }
          this.texts[0].text = "<b>" + element.name + "</b></br><span>" + element.address + "</span>";
          this.EditItem.visit_address = element.name + "(" + element.address + ")";
          this.latLng = element.location;
          return;
        }
      });
    },
  },
};
</script>

<style scoped="scoped">
/**找到表头那一行，然后把里面的复选框隐藏掉**/
::v-deep .el-table__header-wrapper .el-table__header .el-checkbox {
  display: none;
}
.spuser {
  display: inline-block;
  border: 1px solid rgb(255, 148, 62);

  padding: 2px;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  padding: 4px 10px;
}

.spuser img {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
}

.imgaddbtn {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dotted #ccc;
  font-size: 30px;
  cursor: pointer;
}

.imgremove {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;

  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
  color: #ccc;
}

.imgremove:hover {
  color: orange;
}
</style>
